import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";
import "gatsby-theme-starberry-lomondgroup/src/components/FeaturedLogo/FeaturedLogo.scss"

import { Pagination, Navigation, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Swiper styles
import 'swiper/scss';
import 'swiper/scss/autoplay' // styles required for Autoplay module
import 'swiper/scss/effect-fade' // styles required for Fade Effect module
import 'swiper/scss/navigation' // styles required for Navigation module
import 'swiper/scss/pagination' // styles required for Pagination module

const ClientLogo = ({Module, GQLPage}) => {

    // var settings = {
    //     dots: false,
    //     arrows: false,
    //     infinite: true,
    //     centerMode:false,
    //     autoplay:true,
    //     autoplaySpeed:3000,
    //     speed: 3000,
    //     variableWidth: true,
    //     draggable:true,
    //     slidesToShow: 1,
    //     responsive: [
    //         {
    //             breakpoint: 992,
    //             settings: {
    //                 dots: true,
    //             },
    //         },

    //     ],
    // };

    return (<>
        {Module?.client_logos_component?.length > 0 &&
        <section className={`features logo_wrapper`}>
            <Container>
                <Row>
                    <Col>
                        <h2>{Module.title ? Module.title : "Featured In"}</h2>
                        <div className="brand-wrap">
                            {/* <Slider {...settings}>
                                {Module?.client_logos_component?.map((item, index) =>{
                                    if(item.client_logo){
                                        return(
                                            <>
                                                {item.link ?
                                                    <Link to={item.link}>
                                                        <ImageModule ImageSrc={item.client_logo} />
                                                    </Link>
                                                :
                                                    <a href={"javascript:;"}>
                                                        <ImageModule ImageSrc={item.client_logo} />
                                                    </a>
                                                }
                                            </>
                                            
                                        )
                                    }                    
                                })}
                            </Slider> */}

                            <Swiper
                                slidesPerView={2}
                                spaceBetween= {16}
                                autoplay={{
                                    delay: 1000,
                                    disableOnInteraction: false,
                                }}
                                loop={true}
                                modules={[Navigation, Pagination, Autoplay, EffectFade]}
                                centeredSlides={true}
                                pagination={{
                                    // el: ".swiper-pagination",
                                    clickable: true,
                                    dynamicBullets: true,                
                                }}
                                breakpoints={{
                                    480: {
                                        slidesPerView: 3,
                                    },
                                    640: {
                                      slidesPerView: 4,
                                      centeredSlides:false
                                    },
                                    840: {
                                      slidesPerView: 5,
                                    },
                                    992: {
                                        slidesPerView: 6,
                                    },
                                    1200: {
                                      slidesPerView: 7,
                                    },
                                    1600: {
                                        slidesPerView: 8,
                                        centeredSlides:false
                                    },
                                }}
                                
                                // {...rest}
                            >
                                {Module?.client_logos_component?.map((item, index) =>{
                                    if(item.client_logo){
                                        return(
                                            <SwiperSlide>
                                                {item.link ?
                                                    <Link to={item.link}>
                                                        <ImageModule ImageSrc={item.client_logo} altText={Module.title} ggfx_results={GQLPage?.ggfx_results}  renderer="srcSet" imagename={"pages.client_logos_component_client_logo.client_logo"} strapi_id={GQLPage?.id} /> 
                                                    </Link>
                                                :
                                                    <a href={"javascript:;"}>
                                                        <ImageModule ImageSrc={item.client_logo} altText={Module.title} ggfx_results={GQLPage?.ggfx_results}  renderer="srcSet" imagename={"pages.client_logos_component_client_logo.client_logo"} strapi_id={GQLPage?.id} /> 
                                                    </a>
                                                }
                                            </SwiperSlide>
                                            
                                        )
                                    }                    
                                })}
                            </Swiper>

                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        }
    </>)
}
export default ClientLogo;