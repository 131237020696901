import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import PlayVideo from "../Play/PlayVideo";

import CTABtnModule from "gatsby-theme-starberry-lomondgroup/src/modules/cta_btn_module";
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";
import PlayVideo from "gatsby-theme-starberry-lomondgroup/src/modules/video_module/PlayVideo";

import "gatsby-theme-starberry-lomondgroup/src/components/Customer/Customer.scss";

const Customer = ({Module, GQLPage, office_template}) => {
    let [isPlay, setPlay] = React.useState(false);
    let [isReadMore, setReadMore] = React.useState(false);

    //console.log("Module customer ==>", Module)

    var videoUrl = Module?.embed_video_url ? Module?.embed_video_url : "";


    var imagename = office_template ? "office.video_section_image.video_image" : "pages.video_section_image.video_image";

    let processedImages = JSON.stringify({});
    if (GQLPage?.imagetransforms?.video_section_image_Transforms) {
        processedImages = GQLPage?.imagetransforms?.video_section_image_Transforms;
    }

    var components_name_cls = Module?.title ? Module?.title.toLowerCase().replace(/ /g, '_') : "";

    return (
        <React.Fragment>
            <section className={`customer ${components_name_cls} ${Module?.bg_color}`}>
                <Container>
                    <Row>
                        <Col>
                            <div className={`customer-wrapper`}>
                                <div className="video-img">
                                    {Module?.image?.url && 
                                        components_name_cls === "our_existing_regions" ?
                                            <ImageModule ImageSrc={Module.image} altText={Module?.title} /> 
                                        :
                                            <ImageModule ImageSrc={Module.image} altText={Module?.title} imagetransforms={processedImages} ggfx_results={GQLPage?.ggfx_results}  renderer="srcSet" imagename={imagename} strapi_id={GQLPage?.id} /> 
                                    }

                                    {videoUrl && 
                                    <button className="video-wrap"
                                        onClick={e => { setPlay(true) }}>
                                        <i className="icon-video">
                                        </i>
                                    </button>
                                    }
                                </div>
                                <div className="video-content">
                                    {Module?.title && <h3>{Module?.title}</h3>}
                                    
                                    {Module?.content && 
                                        <div className="video_text"> 
                                            {Module?.read_more_content ? 
                                                <>
                                                    <ContentModule Content={Module?.content} /> 
                                                    {isReadMore ? <>
                                                            <ContentModule Content={Module?.read_more_content} /> 
                                                            <a href="javascript:;" className="btn-link" onClick={() => setReadMore(false)}>Read Less</a>
                                                        </>
                                                        :
                                                        <a href="javascript:;" className="btn-link" onClick={() => setReadMore(true)}>Read More</a>
                                                    }
                                                </>
                                                :
                                                <ContentModule Content={Module?.content} /> 
                                            }
                                            

                                        </div> 
                                    }

                                    {Module?.Button_CTA && <CTABtnModule button_cta={Module.Button_CTA} /> }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoUrl={videoUrl}
                isAutoPlay={1}
            />
        </React.Fragment>
    );
};
export default Customer;
